<template>
  <div class="form-card">
    <b-row>
      <b-col md="10">
        <h2>{{ form.name }}</h2>
      </b-col>

      <b-col md="2" v-if="!isPreConsultation" :v-can="permissionEdit">
        <StarSVG
          class="star"
          :id="`star_${form.id}`"
          :color="color"
          :stroke="border"
          @click.native="makeFormDefault"
        />
        <b-tooltip
          :target="`star_${form.id}`"
          class="tooltip-star"
          placement="bottom"
        >
          <span class="d-block text-left">
            Transforme esta consulta em uma consulta padrão, ela será sempre o
          </span>
          <span class="d-block text-left">
            modelo padrão quando você iniciar uma nova consulta.
          </span>
        </b-tooltip>
      </b-col>
    </b-row>

    <b-row class="description">
      <b-col class="col-description">
        <p><strong>Descrição:</strong> {{ form.description ? form.description : "Sem descrição." }}</p>
      </b-col>
    </b-row>

    <b-row class="mt-3 bottom">
      <b-col cols="5" v-if="!isPreConsultation">
        <span class="autoFillBtnLabel">
          {{ this.form.active ? 'Ativo' : 'Inativo' }}
        </span>
        <toggle-button
          :v-can="permissionEdit"
          class="autoFillBtn mb-1"
          :width="30"
          :value="Boolean(form.active)"
          :sync="true"
          :height="18"
          :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
          @change="updateActive"
        />
      </b-col>
      <b-col :cols="isPreConsultation ? 12 : 7">
        <b-button
          variant="link"
          class="link"
          @click="edit"
          :v-can="permissionEdit"
        >
          {{ isPreConsultation ? 'Editar pré-consulta' : 'Ver consulta' }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'FormCard',
  components: {
    StarSVG: () => import('@/components/Protocols/StarSVG')
  },
  props: {
    form: Object,
    type: String
  },
  created() {
    if (this.form.default) {
      this.color = '#FFCC18'
      this.border = '#FF6B00'
      return
    }
    this.color = 'white'
    this.border = '#A4B1DF'
  },
  data() {
    return {
      color: null,
      border: null,
    }
  },
  computed: {
    isPreConsultation() {
      return this.type === 'PRE_CONSULTATION'
    },
    redirectLink() {
      return this.form?.type === 'ATTENDANCE'
        ? '/configuracoes-da-consulta/'
        : '/configuracoes-do-exame/'
    },
    permissionEdit() {
      return {
        EXAM: 'ConfConAten6',
        ATTENDANCE: 'ConfConAten4',
        PRE_CONSULTATION: 'ConfConAten5'
      }[this.type]
    },
    permissionCreate() {
      return {
        EXAM: 'ConfConAten3',
        ATTENDANCE: 'ConfConAten2'
      }[this.type]
    }
  },
  methods: {
    async makeFormDefault() {
      try {
        let active = false
        if ((!this.form.active && !this.form.default) || this.form.active) {
          active = true
        }
        const { data } = await this.api.updateForm(this.form.id, {
          default: !this.form.default,
          active
        })
        const text = data.default ? 'adicionado' : 'removido'
        this.$toast.success(`Formulário ${text} ${text === "removido" ? "dos": "aos"} padrões com sucesso!`)
        this.$emit('reloadForms')
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async updateActive() {
      try {
        let defaultForm = this.form.default
        if (this.form.default && this.form.active) {
          defaultForm = false
        }
        const { data } = await this.api.updateForm(this.form.id, {
          active: !this.form.active,
          default: defaultForm
        })

        const label = data.active ? 'ativado' : 'desativado'
        this.$toast.success(`Formulário ${label} com sucesso!`)
        this.form.active = !this.form.active
        this.$emit('reloadForms')
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    edit() {
      if (this.isPreConsultation) {
        return this.$emit('edit-pre-consultation', this.form)
      }
      this.$router.push(`${this.redirectLink}${this.form.id}`)
    }
  },
  watch: {
    'form.default'(value) {
      if (value) {
        this.color = '#FFCC18'
        this.border = '#FF6B00'
        return
      }
      this.color = 'white'
      this.border = '#A4B1DF'
    }
  }
}
</script>

<style lang="scss" scoped>
.form-card {
  width: 100%;
  text-align: left;
  height: 100%;
  background: var(--neutral-000);
  border-radius: 8px;
  padding: 1rem;

  h2 {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.3rem;
    color: var(--type-active);
    margin: 0;
  }

  .star {
    margin: 0 auto;
    margin-left: auto;
  }

  .description {
    margin-top: 1.5rem;
    height: 100px;
    text-overflow: ellipsis;
    height: 4rem;

    .col-description {
      p {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-size: 14px;
        line-height: 1.2rem;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box !important;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        white-space: normal;
        margin: 0;
      }
    }
  }
  .bottom {
    .autoFillBtnLabel {
      margin: 0;
    }

    .link {
      font-weight: 700;
      font-size: 16px;
      padding: 0;
      width: 100%;
      text-align: end;
      @media (min-height: 1600) {
        font-size: 1.6vh;
      }
      @media (max-height: 1600) {
        font-size: 1vw;
      }
    }

    label {
      margin-bottom: -5%;
    }
  }
}
</style>
